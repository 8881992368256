import '../styles/index.scss';
import SmoothScroll from 'smooth-scroll';

const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 750, // default: 300ms
});

window.addEventListener('load', () => {
  // 何件ずつ表示するか
  const readMoreNum = 3;

  // readMoreNumの件数ずつnews-contentを表示する
  const readMore = () => {
    const newsContents = document.querySelectorAll('.news-content:not(.visible)');
    Array.from(newsContents)
      .slice(0, readMoreNum)
      .map((newsContent) => (newsContent.className = `visible ${  newsContent.className}`));
    disableBtn();
  };
  // ボタンにeventを適用
  const readMoreBtn = <HTMLInputElement>document.getElementById('read-more')!;
  readMoreBtn.addEventListener('click', readMore);

  // 非表示要素が0件の場合ボタンをdisabledにする
  const disableBtn = () => {
    if (document.querySelectorAll('.news-content:not(.visible)').length === 0) {
      if (readMoreBtn) {
        readMoreBtn.disabled = true;
      }
    }
  };

  // 初期表示
  readMore();
});
